import { defineStore } from "pinia"
import { getSessionMetricsByProjectId } from "../services/adminBackendClient"
import { SessionMetrics, TimeBucket } from "../types"
import { useProjectStore } from "./projectStore"
import { toast } from "vue3-toastify"
import { collectError } from "@utils/errors"
import sessionStats from "@/mocks/sessionStats"
import { SummaryStats } from "@/types/sessionMetrics"

type SessionMetricsState = {
   loading: boolean
   trace_id?: string
   start_date?: number
   end_date?: number
   as_of_time?: number
   agg_level?: string
   graphs_enabled?: boolean
   summary_stats?: SummaryStats
   time_series?: TimeBucket[]
}

const getDaysBack = (startDate: number) => {
   const now = Math.floor(new Date().getTime() / 1000)
   return Math.floor((now - Number(startDate)) / (24 * 60 * 60))
}

export const useSessionMetricsStore = defineStore("sessionMetricsStore", {
   state: (): SessionMetricsState => {
      return {
         loading: false,
         trace_id: undefined,
         start_date: undefined,
         end_date: undefined,
         as_of_time: undefined,
         agg_level: undefined,
         graphs_enabled: undefined,
         summary_stats: undefined,
         time_series: undefined,
      }
   },
   persist: true,
   actions: {
      getStartDate(daysBack: number) {
         const now = new Date()
         now.setDate(now.getDate() - daysBack)
         now.setUTCHours(0, 0, 0, 0)
         // convert now to epoch time
         return now.getTime() / 1000
      },
      getEndDate(): number {
         // yesterday at 11:59:59 PM UTC
         const now = new Date()
         now.setDate(now.getDate() - 1)
         now.setUTCHours(23, 59, 59, 0)
         // convert now to epoch time
         return now.getTime() / 1000
      },
      async getSessionMetricsByProjectId(
         projectId: number,
         daysBack: number,
      ): Promise<SessionMetrics> {
         this.loading = true
         const startDate = this.getStartDate(daysBack)
         const endDate = this.getEndDate()
         let response = null
         try {
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               return sessionStats
            }
            response = await getSessionMetricsByProjectId(
               projectId,
               startDate,
               endDate,
               this.agg_level!,
            )
         } catch (error) {
            collectError(error as Error)
            this.loading = false
            toast.error("Error occurred while fetching session metrics")
            throw error
         }

         this.trace_id = response.data.trace_id
         this.start_date = response.data.start_date
         this.end_date = response.data.end_date
         this.as_of_time = response.data.as_of_time
         this.agg_level = response.data.agg_level
         this.graphs_enabled = response.data.graphs_enabled
         this.summary_stats = response.data.summary_stats
         this.time_series = response.data.time_series
         this.loading = false

         return response.data
      },
      async getSessionMetrics(
         daysBack: number,
         aggLevel: string,
      ): Promise<SessionMetrics> {
         this.loading = true
         const projectStore = useProjectStore()
         const startDate = this.getStartDate(daysBack)
         const endDate = this.getEndDate()
         const projectId = projectStore.projectId
         if (!projectId) throw new Error("Project ID is not set")
         let response = null
         try {
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = { data: sessionStats }
            } else {
               response = await getSessionMetricsByProjectId(
                  projectId,
                  startDate,
                  endDate,
                  aggLevel,
               )
            }
         } catch (error) {
            collectError(error as Error)
            this.loading = false
            toast.error("Error occurred while fetching session metrics")
            throw error
         }
         this.trace_id = response.data.trace_id
         this.start_date = response.data.start_date
         this.end_date = response.data.end_date
         this.as_of_time = response.data.as_of_time
         this.agg_level = response.data.agg_level
         this.graphs_enabled = response.data.graphs_enabled
         this.summary_stats = response.data.summary_stats
         this.time_series = response.data.time_series
         this.loading = false

         return response.data
      },
   },
   getters: {
      daysBack(state): number | undefined {
         if (state.start_date) {
            return getDaysBack(state.start_date)
         } else {
            return undefined
         }
      },
      hasVariants(state): boolean {
         return (
            state.summary_stats?.variants !== null &&
            state.summary_stats?.variants !== undefined
         )
      },
   },
})
