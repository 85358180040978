<template>
   <div>
      <div class="chart-container">
         <Line
            id="chart"
            ref="line"
            :options="chartOptions"
            :data="finalChartData"
         />
      </div>
   </div>
</template>

<script setup lang="ts">
import {
   ref,
   Ref,
   onMounted,
   onUnmounted,
   VNodeRef,
   PropType,
   computed,
} from "vue"
import { Line } from "vue-chartjs"
import { enUS } from "date-fns/locale"
import { useSessionMetricsStore } from "@/stores"

import {
   Chart as ChartJS,
   Tooltip,
   LineElement,
   ChartData,
   PointElement,
   TimeScale,
   LinearScale,
   ChartOptions,
   Legend,
} from "chart.js"

ChartJS.register(
   Tooltip,
   LineElement,
   TimeScale,
   LinearScale,
   PointElement,
   Legend,
)

import "chartjs-adapter-date-fns"
import { createConversionRateByCombinationDatasets } from "@utils/chartDatasetGenerators"
import { ConversionFilter } from "@/types/graphs"
import { logInfo } from "@utils/logging"
import { getRetroMetroColor } from "@utils/chartColors"

const props = defineProps({
   conversionRateFilter: {
      type: String as PropType<ConversionFilter>,
      required: true,
   },
})

const sessionMetricsStore = useSessionMetricsStore()
const showLegend = computed(() => {
   return props.conversionRateFilter != "all"
})

const line: VNodeRef = ref(null)
const tooltipEnabled = ref(true)

const chartOptions: Ref<ChartOptions<"line">> = computed(() => ({
   responsive: true,
   interaction: {
      mode: "index",
      intersect: false,
   },
   elements: {
      line: {
         tension: 0.4,
      },
   },
   scales: {
      y: {
         beginAtZero: false,
         grid: {
            display: true,
            // lineWidth: (context) => {
            //    const length = context.scale.ticks.length
            //    if (context.index === 0 || context.index === length - 1) {
            //       return 0
            //    }
            //    return 1
            // },
         },
         ticks: {
            // Include a percentage sign in the ticks
            callback: function (tickValue: string | number) {
               return Math.abs(Number(tickValue)).toFixed(2) + "%"
            },
         },
      },
      x: {
         display: true,
         grid: {
            display: false,
         },
         type: "time",
         time: {
            unit: "day",
            round: "day",
            // tooltipFormat: "MMM d HH:00",
         },
         adapters: {
            date: {
               locale: enUS,
            },
         },
         ticks: {
            source: "auto",
            autoSkip: true,
            autoSkipPadding: 75,
            maxRotation: 0,
         },
      },
   },
   plugins: {
      display: tooltipEnabled.value,
      legend: {
         display: showLegend.value,
         position: "top",
         onHover: function (event, legendItem, legend) {
            ;(event.native?.target as HTMLElement)?.classList.add(
               "cursor-pointer",
            )
            tooltipEnabled.value = false
            legend.chart.data.datasets.forEach((e, i) => {
               if (i === legendItem.datasetIndex) {
                  e.hidden = false
               } else {
                  e.hidden = true
               }
            })
            legend.chart.update()
         },
         onClick: function (event, legendItem, legend) {
            legend.chart.data.datasets.forEach((e, i) => {
               if (i === legendItem.datasetIndex) {
                  e.hidden = false
               } else {
                  e.hidden = true
               }
            })
            legend.chart.update()
         },
         onLeave: function (event, legendItem, legend) {
            tooltipEnabled.value = true
            ;(event.native?.target as HTMLElement)?.classList.add("default")
            legend.chart.data.datasets.forEach((e) => {
               e.hidden = false
            })
            legend.chart.update()
         },
      },
      tooltip: {
         enabled: tooltipEnabled.value,
         padding: 10,
         caretPadding: 10,
         itemSort: (a, b) => b.parsed.y - a.parsed.y,
         callbacks: {
            title: (context) => {
               const date = new Date(context[0].parsed.x)
               const options = <Intl.DateTimeFormatOptions>{
                  month: "long",
                  day: "numeric",
               }
               return date.toLocaleString(undefined, options)
            },
            label: function (context) {
               var label = context.dataset.label || ""

               if (label) {
                  label += ": "
               }
               if (context.parsed.y !== null) {
                  label += context.parsed.y + "%"
               }
               return label
            },
            beforeBody: () => "Conversion Rates:",
            afterBody: (context) => {
               const date = new Date(context[0].parsed.x)
               const options = <Intl.DateTimeFormatOptions>{
                  hour: "numeric",
                  timeZoneName: "short",
               }
               return `As of ${date.toLocaleString(undefined, options)}`
            },
         },
      },
   },
}))

const chartData: Ref<ChartData<"line">> = ref({
   labels: [],
   datasets: [],
})

const filteredChartData = computed(() => {
   if (props.conversionRateFilter == "all") {
      return chartData.value
   } else if (props.conversionRateFilter == "top_10") {
      const orderedDatasetsByConversionRate = [
         ...chartData.value.datasets,
      ].sort((a, b) => {
         const nextConversionRate = Number(b.data![b.data!.length - 1])
         const lastConversionRate = Number(a.data[a.data.length - 1])
         return nextConversionRate - lastConversionRate
      })
      const top10Datasets = orderedDatasetsByConversionRate.slice(0, 10)
      return {
         labels: chartData.value.labels,
         datasets: top10Datasets,
      }
   } else if (props.conversionRateFilter == "bottom_10") {
      const orderedDatasetsByConversionRate = [
         ...chartData.value.datasets,
      ].sort((a, b) => {
         const nextConversionRate = Number(b.data![b.data!.length - 1])
         const lastConversionRate = Number(a.data[a.data.length - 1])
         return nextConversionRate - lastConversionRate
      })
      const bottom10Datasets = orderedDatasetsByConversionRate.slice(-10)
      return {
         labels: chartData.value.labels,
         datasets: bottom10Datasets,
      }
   } else {
      logInfo("Invalid conversion rate filter, returning all data.")
      return chartData.value
   }
})

const finalChartData = computed(() => {
   let finalData = [...filteredChartData.value.datasets]
   for (let i = 0; i < finalData.length; i++) {
      const dataset = finalData[i]
      dataset.backgroundColor = getRetroMetroColor(i)
      dataset.borderColor = getRetroMetroColor(i)
   }
   return {
      labels: filteredChartData.value.labels,
      datasets: finalData,
   }
})

const handleResize = () => {
   if (!line.value) return
   line.value.chart.resize()
}

onMounted(() => {
   window.addEventListener("resize", handleResize)
})

onUnmounted(() => {
   window.removeEventListener("resize", handleResize)
})

sessionMetricsStore.$subscribe(() => {
   updateChartData()
   line.value.chart.update()
})

const updateChartData = () => {
   let labels: string[] = sessionMetricsStore
      .time_series!.map((x) => {
         return x.Dt
      })
      .filter((x) => !!x)

   // Alternate data set generators can be found in @/utils
   // This version shows the Holdback conversion rate, and the experimental conversion rate with error bars.
   // Right now, holdback _looks_ better than experimental group because we have so few samples.
   // const datasets = createExpVsHbDatasets(resp)

   // This shows variant conversion rates over time with error bounds.
   // const datasets = createVariantConversionRateDatasets(resp);

   // This shows variant lift relative to the default variant (variant 0)
   const datasets = createConversionRateByCombinationDatasets()
   if (!datasets) {
      throw new Error("No datasets to render")
   }

   // search the datasets array for an object with label: '0' and set it to default
   datasets.forEach((dataset) => {
      if (dataset.label == "0") {
         dataset.label = "Default"
      }
   })

   // This shows what variations are being served over time
   // const datasets = createVariationServeRateDatasets(resp);
   chartData.value = {
      labels: labels,
      datasets: datasets,
   }
}
await updateChartData()
</script>
<style lang="scss">
.chart-container {
   position: relative;
   height: 100%;
   width: 100%;
}
#chart {
   position: relative;
   height: 100%;
   width: 100%;
}
</style>
