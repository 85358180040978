<template>
   <div id="sign-up-container">
      <QCard v-if="showInfoCard" id="mobile-install-card">
         <QCardSection id="mobile-install-card-header-container">
            <div id="mobile-install-card-header-text">
               Start Your 30 Day Free Trial
            </div>
         </QCardSection>
         <QCardSection id="mobile-install-card-main-text" class="text-left">
            <p class="text-left">
               Get up and running with ezbot in as little as 10 minutes.
            </p>
            <h1 id="you-need" class="text-h6">You'll need:</h1>
            <ol>
               <li>A website you want to optimize</li>
               <li>Access to place a Javascript snippet or to source code</li>
            </ol>
            <p id="to-get-started">
               To get started, create an account
               <span id="next-card-reference-mobile">below.</span
               ><span id="next-card-reference-desktop">to the right.</span>
            </p>
            <div id="remind-me-later-container">
               <QBtn
                  id="docs-btn"
                  color="primary"
                  :outline="true"
                  label="Docs"
                  @click="handleDocumentation()"
               ></QBtn>
               <QBtn
                  id="install-desktop"
                  color="primary"
                  :outline="true"
                  label="Remind Me Later"
                  @click="handleRemindMeLater()"
               ></QBtn>
            </div>
         </QCardSection>
      </QCard>
      <SignUp force-redirect-url="/onboarding/create-org" />
   </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { SignUp } from "vue-clerk"
import { useRoute } from "vue-router"

const handleRemindMeLater = () => {
   window.open(
      "https://share.hsforms.com/18DvNvCvRRmWPf7oO8Dm2HAqht4f",
      "_blank",
   )
}
const handleDocumentation = () => {
   window.open("https://docs.ezbot.ai/welcome/", "_blank")
}

const route = useRoute()
const showInfoCard = computed(() => {
   console.log(route.hash)
   // Change this when clerk is updated and we use path-based routing
   // to /validate-email-address
   return !route.hash.includes("verify-email-address")
})
</script>

<style lang="scss" scoped>
#sign-up-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 20px;
   align-items: center;
   min-height: 100vh;
   padding: 1.5em;
   // background-image: linear-gradient(
   //    to top right,
   //    #7e00f7 5%,
   //    #c451af 25%,
   //    #007bff,
   //    #00aaff
   // );
   background-image: linear-gradient(to top right, #007bff, #00aaff);
}

#mobile-install-card {
   width: 100%;
   max-width: 400px;
   background-color: white;
   border-radius: 10px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   ol {
      margin-top: 0.5em;
      padding-inline-start: 15px;
   }
}

#mobile-install-card-header-container {
   background-color: #41c055;
}
#mobile-install-card-header-text {
   color: white;
   font-weight: bold;
   font-size: 1.5rem;
   letter-spacing: -1px;
}
#remind-me-later-container {
   display: flex;
   gap: 20px;
   justify-content: center;
   margin-top: 1em;
}
#next-card-reference-mobile {
   display: inline;
}
#next-card-reference-desktop {
   display: none;
}
#you-need {
   margin-bottom: 0;
}
#to-get-started {
   margin-top: 1em;
}
@media (min-width: 768px) {
   #sign-up-container {
      flex-direction: row;
      height: 100vh;
   }
   #mobile-install-card-header-text {
      padding: 0 2em;
      font-size: 1.75rem;
      letter-spacing: 0px;
   }
   #next-card-reference-mobile {
      display: none;
   }
   #next-card-reference-desktop {
      display: inline;
   }
}
@media (min-width: 1024px) {
   #mobile-install-card {
      max-width: 600px;
   }
   #mobile-install-card-main-text {
      padding: 2em;
   }
   #remind-me-later-container {
      display: flex;
      gap: 20px;
      justify-content: start;
      margin-top: 1em;
   }
}
</style>
