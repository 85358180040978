import {
   Unauthorized,
   SignUp,
   SignedOut,
   Authorize,
   Home,
   SignIn,
   SignOut,
} from "@views/index"
import { requireLogin, requireValidProject } from "../guards/control"
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router"

export default [
   {
      name: "Home",
      path: "",
      component: Home,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      path: "/sign-in",
      name: "SignIn",
      component: SignIn,
   },
   {
      path: "/sign-in/factor-one",
      name: "SignInFactorOne",
      component: SignIn,
   },
   {
      path: "/sign-out",
      name: "SignOut",
      component: SignOut,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireLogin(to, from, next)
      },
   },
   {
      path: "/sign-up",
      name: "SignUp",
      component: SignUp,
      meta: {
         layout: "AppLayoutDefault",
      },
   },
   {
      path: "/sign-up/verify-email-address",
      name: "VerifyEmailAddress",
      component: SignUp,
      meta: {
         layout: "AppLayoutDefault",
      },
   },
   {
      path: "/signed-out",
      name: "SignedOut",
      component: SignedOut,
   },
   {
      path: "/unauthorized",
      name: "Unauthorized",
      component: Unauthorized,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireLogin(to, from, next)
      },
   },
   {
      path: "/authorize",
      name: "Authorize",
      component: Authorize,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
]
